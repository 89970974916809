import { useEffect, useState } from 'react';

export const useLocationHref = () => {
    const [locationHref, setLocationHref] = useState('');

    useEffect(() => {
        setLocationHref(window.location.href);
    }, []);

    return locationHref;
};
